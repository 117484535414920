import React, { useCallback, useEffect, useRef, useState } from 'react'
import cx from 'classnames'

// In order to prevent "flickering" every video needs to have it's own video element.
// You can use the source element of course but resetting `video.src` will cause the
// flickering and probably way more "workaround-code".
//
// TODO: Pause video when page tranisition happens
const Video = ({ duration, onLoaded, onPause, play, src }) => {
  const ref = useRef()
  const handleLoadedData = () => onLoaded(ref)
  const handlePause = () => onPause(ref.current)
  const handleTimeUpdate = () => {
    if (ref.current.currentTime >= duration) {
      ref.current.pause()
    }
  }

  useEffect(() => {
    if (play) {
      // We have to make sure that every time the video is called to "play", it's
      // starting from 0. The video's are never unmounted (to avoid reloading the src).
      ref.current.currentTime = 0
      ref.current.play()
    }
  }, [play])

  return (
    <video
      className={cx(play ? 'd-block' : 'd-none')}
      ref={ref}
      preload="auto"
      muted
      playsInline
      onLoadedData={handleLoadedData}
      onPause={handlePause}
      onTimeUpdate={handleTimeUpdate}
      src={src}
    />
  )
}

Video.defaultProps = {
  duration: 5,
  onLoaded: Function.prototype,
  onPause: Function.prototype,
  play: false,
  video: null
}

// TODO: Fix play/pause errors (see console)
const VideoCarousel = ({ onVideoChange, videos }) => {
  // const isMobile = useRef(mobile())
  const [loadedVideos, setLoadedVideos] = useState([])
  const [playingVideoIdx, setPlayingVideoIdx] = useState(0)

  useEffect(() => {
    if (loadedVideos.length === 1) {
      setPlayingVideoIdx(0)
    }
  }, [loadedVideos])

  useEffect(() => {
    onVideoChange(videos[playingVideoIdx])
  }, [onVideoChange, playingVideoIdx, videos])

  const handleVideoPause = useCallback(
    videoRef => {
      const nextVideoIdx = playingVideoIdx + 1
      // When switching to another tab of your browser, a "pause" will be triggered.
      // Without checking the `currentTime`, we would trigger the next video, while the
      // previous video will keep
      if (videoRef.currentTime >= 5) {
        setPlayingVideoIdx(nextVideoIdx === videos.length ? 0 : nextVideoIdx)
      }
    },
    [playingVideoIdx, videos]
  )

  const handleVideoLoaded = useCallback(ref => setLoadedVideos(draft => draft.concat(ref)), [])

  // Because of the transformations of ".video", we cannot put ".message" in it.
  // TODO: Don't load and play videos on mobile phone.
  return (
    <div className="video-carousel">
      {videos.map(({ name, video }, idx) => (
        <div key={name} className={cx('video', name)}>
          <Video
            id={name}
            duration={6}
            className={name}
            src={video}
            play={idx === playingVideoIdx}
            onLoaded={handleVideoLoaded}
            onPause={handleVideoPause}
          />
        </div>
      ))}
      {!!videos.length && <div className="message">{videos[playingVideoIdx].message}</div>}
    </div>
  )
}

VideoCarousel.defaultProps = {
  onVideoChange: Function.prototype,
  videos: []
}

export default VideoCarousel
