import React from 'react'
import { Alert, Button, Card, CardBody, CardColumns, CardTitle, Container, Jumbotron } from 'reactstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import VideoCarousel from '../components/VideoCarousel'

import { ReactComponent as Ai } from '../assets/svg/illustrations/ai.svg'
import { ReactComponent as SentimentAnalysis } from '../assets/svg/illustrations/sentiment-analysis.svg'
import { ReactComponent as CareerProgress } from '../assets/svg/illustrations/career-progress.svg'
import { ReactComponent as Faq } from '../assets/svg/illustrations/faq.svg'
import { ReactComponent as Result } from '../assets/svg/illustrations/result.svg'
import { ReactComponent as Scientific } from '../assets/svg/illustrations/scientific.svg'
import { ReactComponent as Video } from '../assets/svg/illustrations/video.svg'
import { ReactComponent as Airtame } from '../assets/svg/companies/airtame.svg'
import { ReactComponent as Bmw } from '../assets/svg/companies/bmw.svg'
import { ReactComponent as Lufthansa } from '../assets/svg/companies/lufthansa.svg'
import { ReactComponent as Personio } from '../assets/svg/companies/personio.svg'
import AiVideo from '../assets/videos/ai.mp4'
import InterviewVideo from '../assets/videos/interview.mp4'
import LookingIntoCamera from '../assets/videos/looking-into-camera.mp4'

const videos = [
  {
    name: 'interview',
    video: InterviewVideo
  },
  {
    name: 'ai',
    video: AiVideo
  },
  {
    name: 'into-camera',
    video: LookingIntoCamera
  }
]

const LandingPage = () => (
  <div className="default-layout-page landing-page">
    <Jumbotron fluid>
      <Container>
        <div className="text-wrapper">
          <h1 className="display-3">
            Psychologisch fundiertes Feedback zu Deinem ersten Eindruck - mit KI.
          </h1>
          <p className="lead">
            Mit Retorio kannst Du ein Bewerbungsgespräch simulieren und Deine Außenwirkung von einer
            künstlichen Intelligenz beurteilen lassen. Außerdem geben wir Dir Tipps, was Du in einem
            echten Gespräch besser machen könntest.
          </p>
        </div>
        <Button className="recorder-btn" size="lg" tag={Link} to="/recorder">
          <span className="d-flex align-items-center">Jetzt beginnen</span>
          <span className="hint small">
            Die erste Aufnahme inkl. Analyse ist kostenlos und exklusiv für XING ProJobs Mitglieder
          </span>
        </Button>
      </Container>
      <VideoCarousel videos={videos} />
      <div className="video-overlay" />
    </Jumbotron>
    <Container className="py-5 content mt-4 mt-md-0 explanations">
      <Card>
        <h1 className="h1 px-5 pt-5 text-center w-100">
          Wie funktioniert's?
          <small className="d-block h4 font-weight-normal text-muted">
            Unkompliziert, sicher und transparent
          </small>
        </h1>
        <div className="px-4 px-lg-5 my-4 mb-md-0 w-100">
          <Alert color="info" className="d-flex align-items-center mb-0">
            <FontAwesomeIcon className="h5 mr-2 mb-0" icon={faInfoCircle} />
            Um unser Produkt kennenzulernen ist eine Aufnahme für Dich als XING ProJobs Mitglied
            kostenfrei.
          </Alert>
        </div>
        <CardBody className="pr-lg-4 pb-lg-4">
          <CardTitle>
            <h4>
              <span>
                <small>1.</small> Simulation eines Bewerbungsgesprächs
              </span>
            </h4>
          </CardTitle>
          <p className="mb-0">
            <Faq className="illustration float-left mr-4 my-3 order-md-1" />
            <span>
              Nachdem Du Dich mit Deinem XING Konto erfolgreich angemeldet hast, blenden wir unseren
              Recorder ein, der Dich durch den Prozess begleitet. Keine Angst, die Aufnahme startet erst
              auf Dein Kommando.
            </span>
          </p>
        </CardBody>
        <CardBody className="pl-lg-4 pb-lg-4">
          <CardTitle>
            <h4>
              <span>
                <small>2.</small> Aufnehmen und Fragen beantworten
              </span>
            </h4>
          </CardTitle>
          <p className="mb-0">
            <Video className="illustration float-right ml-4 my-3 order-md-1" />
            <span>
              Sobald die erste Frage eingeblendet wird, kannst Du den Befehl zum Aufnehmen geben. Die
              Aufnahme wird Dir anschließend angezeigt und Du kannst fortfahren oder es erneut versuchen,
              sollte sie Dir nicht gefallen.
            </span>
          </p>
        </CardBody>
        <CardBody className="pr-lg-4 pt-lg-4">
          <CardTitle>
            <h4>
              <span>
                <small>3.</small> Analyse durch die KI
              </span>
            </h4>
          </CardTitle>
          <p className="mb-0">
            <Ai className="illustration float-left mr-4 my-3 order-md-1" />
            <span>
              Die KI analysiert Dein verbales und nonverbales Verhalten. Das umfasst unter anderem Deine
              Gestik, Mimik als auch die Sprechgeschwindigkeit. Sie hat durch tausende Videos gelernt,
              Verhaltensmuster zu interpretieren und in eine wissenschaftliche Taxonomie zu gießen.
              <br />
              <br />
              Liegt uns Dein Ergebnis vor, wirst Du per E-Mail benachrichtigt (sollte i.d.R. nicht länger
              als 30 Minuten dauern).
            </span>
          </p>
        </CardBody>
        <CardBody className="pl-lg-4 pt-lg-4">
          <CardTitle>
            <h4>
              <span>
                <small>4.</small> Dein Ergebnis
              </span>
            </h4>
          </CardTitle>
          <p className="mb-0">
            <Result className="illustration float-right ml-4 my-3 order-md-1" />
            <span>
              Unsere E-Mail Benachrichtigung beinhaltet einen Link zu Deinem Ergebnis. Dort
              veranschaulichen wir das Big 5 Modell, markieren Deine Werte und erklären, was dies für
              Dich bedeutet.
              <br />
              <br />
              <strong className="text-dark">Wichtig:</strong> Deine Daten werden an keinen Dritten
              weitergegeben und Du kannst zu jeder Zeit eine Löschung der Daten anfordern.
            </span>
          </p>
        </CardBody>
        <div className="d-flex flex-column align-items-center w-100 px-3 px-md-5 pt-3 pb-5 text-center">
          <h5 className="font-weight-normal mb-3">
            Finde noch heute heraus, wie andere Dich wahrnehmen:
          </h5>
          <Button className="recorder-btn" size="lg" tag={Link} to="/recorder">
            <span className="d-flex align-items-center">Jetzt beginnen</span>
            <span className="hint small">Die erste Aufnahme inkl. Analyse ist kostenlos.</span>
          </Button>
        </div>
      </Card>
    </Container>
    <Container className="companies">
      <h2 className="text-center mb-5">Marken, die Retorio vertrauen</h2>
      <div className="logos">
        <Airtame />
        <Bmw className="bmw" />
        <Lufthansa />
        <Personio className="personio" />
      </div>
    </Container>
    <Container className="pt-5 content benefits">
      <CardColumns>
        <Card>
          <h1 className="h1 px-5 pt-5 text-center w-100">
            Wie Retorio Dir helfen kann
            <small className="d-block h4 font-weight-normal text-muted"></small>
          </h1>
          <CardBody>
            <h2>Der nächste Schliff für Deine Karriere</h2>
            <p>
              <CareerProgress className="illustration float-left mr-4" />
              <span>
                Bei der Einstellung eines neuen Mitarbeiters kommt es oft weniger auf harte Fakten an,
                sondern mehr auf die Persönlichkeit und wie gut der/die Bewerber(in) zum Team passt.
              </span>
            </p>
          </CardBody>
          <CardBody>
            <h2 className="mt-0">Objektiv und unabhängig durch KI</h2>
            <p>
              <SentimentAnalysis className="illustration float-right ml-4 order-md-1" />
              <span>
                Das Feedback von Retorio basiert auf wissenschaftlichen und validierten Studien und kann
                Dir helfen einen neuen Eindruck über Dich selbst zu gewinnen.
              </span>
              <br />
              <br />
              <strong className="text-dark">Interessant:</strong> Häufiger Grund für eine Beendigung des
              Arbeitsverhältnisses ist Inkompatibilität im Team.
            </p>
          </CardBody>
          <div className="d-flex flex-column align-items-center w-100 pb-3">
            <Button className="recorder-btn" size="lg" tag={Link} to="/recorder">
              <span className="d-flex align-items-center">Jetzt beginnen</span>
              <span className="hint small">Die erste Aufnahme inkl. Analyse ist kostenlos.</span>
            </Button>
          </div>
          <CardBody className="w-100">
            <h2>Basierend auf wissenschaftlicher Arbeit</h2>
            <p className="mb-0">
              <Scientific className="illustration float-right ml-4 order-md-1" />
              <span>
                Über das Big 5 oder auch Fünf-Faktoren Model kann Retorio auf 70 Jahre von
                Persönlichkeitsforschung zurückgreifen. Das Model ermöglicht es, einzigartige
                Charakteristika und Emotionen in eine Ordnung zu bringen, um sie so greifbar zu machen.
                Das Big 5 Modell ist eine der am weitesten erforschten und zuverlässigsten Taxonomien.
                <br />
                <br />
                <Button color="primary" outline href="https://www.retorio.com/retorio-big-five-overview">
                  Das Big 5 Modell
                </Button>
              </span>
            </p>
          </CardBody>
        </Card>
      </CardColumns>
    </Container>
    <Container className="pt-3 content">
      <CardColumns>
        <Card className="mb-0">
          <h1 className="h1 px-5 pt-5 mb-0 text-center w-100">
            Du brauchst Hilfe?
            <small className="d-block h4 font-weight-normal text-muted">
              Support von (echten) Menschen
            </small>
          </h1>
          <CardBody className="d-flex flex-column align-items-center">
            <p className="mb-0 mb-md-4">
              Noch können wir keinen Telefonservice anbieten, aber über E-Mail stehen wir mit Rat und Tat
              zur Seite. Du hast Fragen? Anregungen? Lob? Tadel? Schreib uns an:
            </p>
            <a className="h5 font-weight-normal" href="mailto:xing-projobs@retorio.com">
              xing-projobs@retorio.com
            </a>
          </CardBody>
          <CardBody>
            <p className="mb-0 mb-md-2 mb-lg-4">
              Du möchtest, dass wir deine Daten löschen? Kein Problem!
            </p>
            <div className="d-flex justify-content-center w-100 pt-3 pt-lg-0">
              <Button
                color="secondary"
                type="button"
                onClick={() =>
                  (window.location.href = `mailto:xing@retorio.com?subject=Datenlöschung Retorio – XING ProJobs&body=Hallo, ich bitte um komplette Löschung meiner personenbezogenen Daten und eine Bestätigung dessen. Viele Grüße`)
                }
              >
                Löschung anfordern
              </Button>
            </div>
          </CardBody>
        </Card>
      </CardColumns>
    </Container>
  </div>
)

export default LandingPage
