import React, { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import { omit } from 'lodash-es'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'

const Segment = ({ active, onClick, segment, width }) => {
  const handleClick = () => onClick(segment)

  return (
    segment && (
      <div
        key={segment.id}
        className={cx('segment', active && 'active')}
        onClick={handleClick}
        style={{ width }}
      >
        <h6 className="field-label">{segment.label}</h6>
      </div>
    )
  )
}

Segment.defaultProps = {
  active: false,
  onClick: Function.prototype,
  segment: null,
  width: 0
}

// `averageLength` is the calculated length relative to the `max` value of Bar-component.
const Segments = ({ averageLength, onActiveSegmentChange, segments, value }) => {
  const [activeSegment, setActiveSegment] = useState(null)

  const isActiveSegment = useCallback(
    index => {
      const rangeStart = +parseFloat(index * averageLength).toFixed(1)
      const rangeEnd = +parseFloat(rangeStart + averageLength).toFixed(1)
      return rangeEnd >= value && value >= rangeStart
    },
    [averageLength, value]
  )

  const getActiveSegmentByValue = useCallback(
    segments =>
      segments.reduce((activeSegment, segment, index) => {
        return activeSegment ? activeSegment : isActiveSegment(index) && segment
      }, null),
    [isActiveSegment]
  )

  const handleSegmentClick = useCallback(
    segment =>
      setActiveSegment(segment.id === activeSegment.id ? getActiveSegmentByValue(segments) : segment),
    [activeSegment, getActiveSegmentByValue, segments]
  )

  useEffect(() => {
    if (segments) {
      setActiveSegment(getActiveSegmentByValue(segments))
    }
  }, [getActiveSegmentByValue, segments])

  useEffect(() => {
    onActiveSegmentChange(activeSegment)
  }, [activeSegment, onActiveSegmentChange])

  if (!segments) return null

  return (
    <div className="segments">
      {segments.map(segment => (
        <Segment
          key={segment.id}
          onClick={handleSegmentClick}
          segment={segment}
          active={activeSegment && activeSegment.id === segment.id}
          width={`${100 / segments.length}%`}
        />
      ))}
    </div>
  )
}

Segments.defaultProps = {
  averageLength: 0,
  onActiveSegmentChange: Function.prototype,
  segments: null,
  value: 0
}

// The Bar component's goal is to support multiple values. In the first version tho
// we only use the first value in `values`.
const Bar = ({ field, max, segments, values }) => {
  const averageLength = segments ? max / segments.length : max
  // `originalSegment` is the segment which is active by the result
  const [originalSegment, setOriginalSegment] = useState(null)
  const [activeSegment, setActiveSegment] = useState(null)
  const [markerPos, setMarkerPos] = useState({
    current: values[0] * 100,
    original: values[0] * 100
  })

  const calculateMarkerPosition = useCallback(
    segment => {
      const index = segments.findIndex(({ id }) => id === segment.id)
      const rangeStart = index * averageLength
      return (rangeStart + averageLength / 2) * 100
    },
    [averageLength, segments]
  )

  const handleActiveSegmentChange = useCallback(segment => {
    setActiveSegment(draft => (segment && draft ? (segment.id === draft.id ? null : segment) : segment))
  }, [])

  // By default `activeSegment` is the segment the result's value belongs to.
  // The user can click on another segment to see it's label and description.
  // If the user clicks on the same segment (which is not the result segment),
  // we toggle back the original result segment.
  useEffect(() => {
    if (!originalSegment) {
      setOriginalSegment(activeSegment)
    }
    if (originalSegment && !activeSegment) {
      setActiveSegment(originalSegment)
    }
  }, [activeSegment, originalSegment])

  useEffect(() => {
    if (originalSegment) {
      if (activeSegment) {
        setMarkerPos(draft => ({
          ...draft,
          current:
            activeSegment.id === originalSegment.id
              ? draft.original
              : calculateMarkerPosition(activeSegment)
        }))
      } else {
        setMarkerPos(draft => ({
          ...draft,
          current: draft.original
        }))
      }
    }
  }, [activeSegment, calculateMarkerPosition, originalSegment])

  return (
    <div className="bar">
      <h5 className="field">{field}</h5>
      <div className="track">
        <Segments
          onActiveSegmentChange={handleActiveSegmentChange}
          segments={segments}
          value={values[0]}
          averageLength={averageLength}
        />
        <div className="line-marker" style={{ left: `${markerPos.original}%` }} />
      </div>
      <div className="description">
        <div className="marker" style={{ left: `${markerPos.current}%` }}>
          <FontAwesomeIcon icon={faCaretUp} />
        </div>
        {activeSegment && activeSegment.description}
      </div>
    </div>
  )
}

Bar.defaultProps = {
  segments: null,
  min: 0,
  max: 0,
  values: []
}

const BarChart = ({ chart, className }) => {
  const { components, settings } = {
    settings: {
      min: 0,
      max: 100,
      ...chart.settings
    },
    components: {
      Bar: ({ children }) => children,
      Chart: ({ children }) => children,
      ...chart.components
    },
    dataset: chart.dataset
  }

  return (
    <div className={cx('bar-chart', className)}>
      <components.Chart>
        {chart.dataset.map(data => (
          <components.Bar key={data.field}>
            <Bar {...omit(settings, 'components')} {...data} />
          </components.Bar>
        ))}
      </components.Chart>
    </div>
  )
}

BarChart.defaultProps = {
  chart: {
    settings: {
      min: 0,
      max: 100
    },
    dataset: []
  }
}

export default BarChart
