import React, { useEffect, useRef, useState } from 'react'

import ChartDescription from './ChartDescription'

const CompareValueBar = ({ description, max, value }) => {
  const ref = useRef()
  const [left, setLeft] = useState(0)

  useEffect(() => {
    if (ref.current) {
      setLeft(ref.current.offsetWidth * Math.min(value, max))
    }
  }, [max, value])

  return (
    <div ref={ref} className="compare-value-bar">
      <div className="bar" />
      <div className="line-marker" style={{ left }} />
      <ChartDescription markerPos={left}>{description}</ChartDescription>
    </div>
  )
}

CompareValueBar.defaultProps = {
  min: 0,
  max: 1
}

export default CompareValueBar
