import React from 'react'
import cx from 'classnames'
import { AnimatedSwitch, spring } from 'react-router-transition'

const glide = val =>
  spring(val, {
    stiffness: 174,
    damping: 19
  })

const transitionFns = { glide }

// With our `Switch` we provide a predefined set of animations to make the look and
// feel consistent. You can use any spring functions provided in `transitionFns`.
const Switch = ({ children, className, transitions, ...props }) => (
  <AnimatedSwitch className={cx('route-switch', className)} {...transitions(transitionFns)} {...props}>
    {children}
  </AnimatedSwitch>
)

Switch.defaultProps = {
  mapStyles: styles => ({
    opacity: styles.opacity,
    transform: `translateX(${styles.offset}px)`
  }),
  transitions: ({ glide }) => ({
    atEnter: {
      offset: 200,
      opacity: 0
    },
    atLeave: {
      offset: glide(100),
      opacity: glide(0)
    },
    atActive: {
      offset: glide(0),
      opacity: glide(1)
    }
  })
}

export default Switch
