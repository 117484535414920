import React, { useEffect } from 'react'
import cx from 'classnames'
import { get } from 'lodash-es'
import useFetch from 'use-http'
import { Button, Card, CardBody, Col, Container, Row, UncontrolledAlert } from 'reactstrap'
import { BigPlayButton, Player } from 'video-react'
import mobile from 'is-mobile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faShareAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import descriptions from '../descriptions.json'
import { ReactComponent as BugFixing } from '../assets/svg/illustrations/bug-fixing.svg'
// import BuyMoreButton from '../components/BuyMoreButton'
import Big5Chart from '../components/Big5Chart'
import LoadingSpinner from '../components/LoadingSpinner'
import Logo from '../components/Logo'
import ResultSummary from '../components/ResultSummary'
import TaskFitCharacteristic from '../components/TaskFitCharacteristic'

const isMobile = mobile()

const API_RESULT_URL = process.env.REACT_APP_API_URL + '/recordings/'

const ResponsiveChartBar = ({ children }) =>
  isMobile ? (
    <Col className="mt-0 mt-md-3 px-0" xs={24}>
      <Card>
        <CardBody>{children}</CardBody>
      </Card>
    </Col>
  ) : (
    <Col className="pb-4" xs={24} lg={12}>
      {children}
    </Col>
  )

const ResponsiveChart = ({ children }) => <Row>{children}</Row>

const ResultPage = ({
  location: { search },
  match: {
    params: { id }
  }
}) => {
  const resultUrl = API_RESULT_URL + id + search
  const {
    data = null,
    error,
    loading
  } = useFetch(
    resultUrl,
    {
      onNewData: (_, payload) => payload
    },
    [resultUrl]
  )

  useEffect(() => {
    if (loading || error || !data) return

    const email = get(data, ['meta', 'email'])

    if (!email) return

    localStorage.setItem('retorio-last-results-url', `/result/${id}${search}`)
  }, [loading, data, error, id, search])

  return (
    <div className={cx('result-page', true && 'is-fetching')}>
      <Container>
        <div className="branding w-100">
          <Logo to="/" />
        </div>
        {error ? (
          <div className="error">
            <h2 className="mb-5">Fehler</h2>
            <BugFixing height="250" />
            <span className="text-center mt-5 w-75">Es scheint ein Fehler aufgetreten zu sein.</span>
          </div>
        ) : (
          <>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <div className="result-container">
                <div className="mb-4 text-center">
                  <h2 className="mt-5">
                    <span className="font-weight-normal">Ergebnis von {data.meta.name}</span>
                  </h2>
                  <span className="text-muted">
                    erstellt am {new Date(data.createdAt).toLocaleDateString()}
                  </span>
                </div>
                <Player fluid={false} playsInline width={640} height={480}>
                  <source key={id} src={data.recordings[0].videoUrl} />
                  <BigPlayButton position="center" />
                </Player>
                <div className="separator" />
                <div className="text-center">
                  <h1>So nimmt dich unsere KI wahr</h1>
                  <h4 className="title">
                    Kurz und knapp
                    <small className="text-muted">
                      Das Ergebnis in kurzen Worten und Tipps für das nächste Interview.
                    </small>
                  </h4>
                  <ResultSummary
                    openness={data.result.openness}
                    conscientiousness={data.result.conscientiousness}
                    extraversion={data.result.extraversion}
                    agreeableness={data.result.agreeableness}
                    neuroticism={data.result.neuroticism}
                  />
                </div>
                <h4 className="title mt-5 mb-3">
                  Deine Auswertung im Detail
                  <small className="text-muted">
                    Das Ergebnis in kurzen Worten und Tipps für das nächste Interview.
                  </small>
                </h4>
                <UncontrolledAlert className="mb-4 d-flex align-items-center" color="light" fade>
                  <FontAwesomeIcon className="h3 mr-3 mb-0" icon={faInfoCircle} />
                  <span>
                    {descriptions.general.big5}{' '}
                    <a href="https://www.retorio.com/retorio-big-five-overview">klicke hier</a>.
                  </span>
                </UncontrolledAlert>
                <Big5Chart
                  components={{ Bar: ResponsiveChartBar, Chart: ResponsiveChart }}
                  values={data.result}
                />
                <div className="separator" />
                <h4 className="text-center">Job-Orientierung</h4>
                <UncontrolledAlert className="mb-4 d-flex align-items-center" color="light" fade>
                  <FontAwesomeIcon className="h3 mr-3 mb-0" icon={faInfoCircle} />
                  <span>{descriptions.general.taskFit}</span>
                </UncontrolledAlert>
                <Row>
                  {Object.entries(descriptions.taskFit).map(([taskId, { label }]) => (
                    <Col xs={24} md={12} key={taskId}>
                      <TaskFitCharacteristic
                        id={taskId}
                        label={label}
                        target={data.benchmarkProfile[taskId]}
                        value={data.result[taskId]}
                      />
                    </Col>
                  ))}
                </Row>
                <div className="mt-5 actions">
                  <Button
                    className="mr-1"
                    color="secondary"
                    type="button"
                    onClick={() =>
                      (window.location.href = `mailto:xing-projobs@retorio.com?subject=Datenlöschung Retorio – XING ProJobs&body=Hallo, ich bitte um komplette Löschung meiner personenbezogenen Daten und eine Bestätigung dessen. Viele Grüße`)
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon={faTrashAlt} />
                    Daten löschen
                  </Button>
                  <Button type="button">
                    <FontAwesomeIcon className="mr-2" icon={faShareAlt} />
                    Teilen
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  )
}

export default ResultPage
