import React from 'react'
import { Button, Container, Navbar, NavbarText, Nav, NavItem } from 'reactstrap'
// import { NavLink } from 'react-router-dom'

import Logo from './Logo'
import useXing from '../hooks/useXing'

const HeaderNavbar = () => {
  const { user, xing } = useXing()
  // const lastResultsUrl = localStorage.getItem('retorio-last-results-url')
  const handleLogout = () => {
    xing.logout()
    window.location.reload()
  }

  return (
    <Navbar className="header-navbar" expand="md">
      <Container>
        <Logo to="/" />
        <Nav navbar>
          <NavItem>
            <NavbarText>
              <small className="user-status">Dein Status:</small>
              {user ? 'Angemeldet' : 'Nicht angemeldet'}
            </NavbarText>
          </NavItem>
          {user && (
            <NavItem>
              <Button color="primary" size="sm" onClick={handleLogout}>
                Abmelden
              </Button>
            </NavItem>
          )}
          {/*<NavItem>
            <Button
              color="primary"
              disabled={!lastResultsUrl}
              size="sm"
              tag={NavLink}
              to={lastResultsUrl || '/'}
            >
              Dein letztes Ergebnis
            </Button>
          </NavItem>*/}
        </Nav>
      </Container>
    </Navbar>
  )
}

export default HeaderNavbar
