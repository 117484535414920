import React from 'react'

import descriptions from '../descriptions.json'
import BarChart from './BarChart'

const { big5 } = descriptions

const Big5Chart = ({ components, values }) => {
  const chartData = {
    settings: {
      min: 0,
      max: 1
    },
    components,
    dataset: [
      {
        field: 'Offenheit',
        segments: [
          {
            id: 'conservative',
            label: 'Konventionell',
            description: <div>{big5.openness.conservative}</div>
          },
          {
            id: 'persistent',
            label: 'Beharrlich',
            description: <div>{big5.openness.persistent}</div>
          },
          {
            id: 'downtoearth',
            label: 'Bodenständig',
            description: <div>{big5.openness.downtoearth}</div>
          },
          {
            id: 'receptive',
            label: 'Interessiert',
            description: <div>{big5.openness.receptive}</div>
          },
          {
            id: 'innovative',
            label: 'Innovativ',
            description: <div>{big5.openness.innovative}</div>
          }
        ],
        values: [values.openness || 0]
      },
      {
        field: 'Gewissenhaftigkeit',
        segments: [
          {
            id: 'spontaneous',
            label: 'Spontan',
            description: <div>{big5.conscientiousness.spontaneous}</div>
          },
          {
            id: 'easygoing',
            label: 'Locker',
            description: <div>{big5.conscientiousness.easygoing}</div>
          },
          {
            id: 'practical',
            label: 'Praktisch',
            description: <div>{big5.conscientiousness.practical}</div>
          },
          {
            id: 'conscientiousness',
            label: 'Pflichtbewusst',
            description: <div>{big5.conscientiousness.conscientiousness}</div>
          },
          {
            id: 'disciplined',
            label: 'Diszipliniert',
            description: <div>{big5.conscientiousness.disciplined}</div>
          }
        ],
        values: [values.conscientiousness || 0]
      },
      {
        field: 'Extraversion',
        segments: [
          {
            id: 'reserved',
            label: 'Reserviert',
            description: <div>{big5.extraversion.reserved}</div>
          },
          {
            id: 'discreet',
            label: 'Zurückhaltend',
            description: <div>{big5.extraversion.discreet}</div>
          },
          {
            id: 'lively',
            label: 'Lebhaft',
            description: <div>{big5.extraversion.lively}</div>
          },
          {
            id: 'talkative',
            label: 'Aktiv',
            description: <div>{big5.extraversion.talkative}</div>
          },
          {
            id: 'energetic',
            label: 'Energetisch',
            description: <div>{big5.extraversion.energetic}</div>
          }
        ],
        values: [values.extraversion || 0]
      },
      {
        field: 'Verträglichkeit',
        segments: [
          {
            id: 'competitive',
            label: 'Kompetitiv',
            description: <div>{big5.agreeableness.competitive}</div>
          },
          {
            id: 'demanding',
            label: 'Fordernd',
            description: <div>{big5.agreeableness.demanding}</div>
          },
          {
            id: 'affable',
            label: 'Umgänglich',
            description: <div>{big5.agreeableness.affable}</div>
          },
          {
            id: 'sincere',
            label: 'Aufrichtig',
            description: <div>{big5.agreeableness.sincere}</div>
          },
          {
            id: 'friendly',
            label: 'Freundlich',
            description: <div>{big5.agreeableness.friendly}</div>
          }
        ],
        values: [values.agreeableness || 0]
      },
      {
        field: 'Neurotizismus',
        segments: [
          {
            id: 'resilient',
            label: 'Belastbar',
            description: <div>{big5.neuroticism.resilient}</div>
          },
          {
            id: 'calm',
            label: 'Ruhig',
            description: <div>{big5.neuroticism.calm}</div>
          },
          {
            id: 'balanced',
            label: 'Ausgewogen',
            description: <div>{big5.neuroticism.balanced}</div>
          },
          {
            id: 'cautious',
            label: 'Vorsichtig',
            description: <div>{big5.neuroticism.cautious}</div>
          },
          {
            id: 'emotional',
            label: 'Emotional',
            description: <div>{big5.neuroticism.emotional}</div>
          }
        ],
        values: [values.neuroticism || 0]
      }
    ]
  }

  return <BarChart chart={chartData} />
}

Big5Chart.defaultProps = {
  components: {},
  values: {}
}

export default Big5Chart
