import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route as BaseRoute } from 'react-router-dom'
import cookie from 'js-cookie'

import './index.scss'

import CookiesConsent from './components/CookiesConsent'
import LanguageProvider from './components/LanguageProvider'
import Route from './components/Route'
import Switch from './components/Switch'
import XingProvider from './components/XingProvider'
import LandingPage from './routes/LandingPage'
import RecorderPage from './routes/RecorderPage'
import ResultPage from './routes/ResultPage'

const App = () => {
  // There seems to be login issues from or with XING. XING stores the important information in a
  // cookie called "xing_p_lw_s_<id>". But this cookie is only valid for the current session and
  // when the user reopens the browser and visits the recorder page, he is stuck in an endless loop
  // because we get an 401 Unauthorized error from the XING API. I don't think this is us, who do
  // something wrong.
  //
  // To prevent the endless loading, I will delete all XING cookies, which are:
  // - s_fid
  // - xing_plugins_login_with_activated
  // - xing_p_lw_s_<id>
  useEffect(() => {
    const allCookies = cookie.get()

    Object.keys(allCookies).forEach(name => {
      const deletable =
        name.match(/^xing_p_lw_s_[a-z0-9]+$/) ||
        name === 's_fid' ||
        name === 'xing_plugins_login_with_activated'
      if (deletable) cookie.remove(name)
    })
  }, [])

  return (
    <div className="app">
      <Switch>
        <BaseRoute path="/result/:id" component={ResultPage} />
        <BaseRoute path="/candidate/result/:id" component={ResultPage} />
        <Route path="/recorder" component={RecorderPage} />
        <Route path="/" exact component={LandingPage} />
      </Switch>
      <CookiesConsent />
    </div>
  )
}

ReactDOM.render(
  <Router>
    <XingProvider consumerKey={process.env.REACT_APP_XING_CONSUMER_KEY}>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </XingProvider>
  </Router>,
  document.getElementById('root')
)
