import React from 'react'
import { Spinner } from 'reactstrap'

const LoadingSpinner = ({ children, ...props }) => (
  <div className="loading-spinner">
    <Spinner {...props} />
    <span className="ml-2">{children}</span>
  </div>
)

LoadingSpinner.defaultProps = {
  children: 'Daten werden geladen...',
  color: 'primary',
  size: 'lg'
}

export default LoadingSpinner
