import React, { useMemo } from 'react'
import cx from 'classnames'

import descriptions from '../descriptions.json'
import CompareValueBar from './CompareValueBar'

const TaskFitCharacteristic = ({ className, label, id, ...barProps }) => {
  const { value } = barProps
  const explanation = useMemo(() => {
    const { low, mid, high } = descriptions.taskFit[id]
    return value >= 0.66 ? high : value < 0.66 && value >= 0.33 ? mid : low
  }, [id, value])

  return (
    <div className={cx('task-fit-characteristic', className)}>
      <h5 className="font-weight-normal text-center">{label}</h5>
      <CompareValueBar description={explanation} {...barProps} />
    </div>
  )
}

export default TaskFitCharacteristic
