import React from 'react'
import { Link } from 'react-router-dom'
import { NavbarText, NavLink } from 'reactstrap'

const ExternalLink = props => {
  const { children, component: Component, nav, tag, text, ...rest } = props
  const LinkComponent = nav ? (text ? NavbarText : NavLink) : Component

  return (
    <LinkComponent {...rest} tag={tag} target="_blank" rel="noopener noreferrer">
      {children}
    </LinkComponent>
  )
}

ExternalLink.defaultProps = {
  component: Link,
  nav: false,
  tag: 'a',
  text: false
}

export default ExternalLink
