import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import useXing from '../hooks/useXing'

const { REACT_APP_XING_LOGIN_ID } = process.env

const XingLogin = forwardRef(({ consumerKey, onAuth, onUnmount, ...props }, ref) => {
  const { setUser, xing } = useXing()
  const [error, setError] = useState(null)

  useEffect(() => {
    window.onXingAuthLogin = response => {
      const { user } = response

      if (response.error) {
        setError(error)
      }

      if (user) {
        setUser(user)
      }

      onAuth(response)
    }
  }, [error, onAuth, setUser])

  useEffect(() => {
    return () => {
      if (xing) {
        // This is important. XING will re-render the login button only if that plugin id is on `false`.
        xing.initialized[REACT_APP_XING_LOGIN_ID] = false
      }
      onUnmount()
    }
  }, [onUnmount, xing])

  return (
    <div ref={ref} {...props}>
      <script type="xing/login">
        {`{ "consumer_key": "${consumerKey}", "size": "xlarge", "language": "de" }`}
      </script>
    </div>
  )
})

XingLogin.displayName = 'XingLogin'

XingLogin.propTypes = {
  consumerKey: PropTypes.string.isRequired,
  onAuth: PropTypes.func,
  onUnmount: PropTypes.func
}

XingLogin.defaultProps = {
  onAuth: Function.prototype,
  onUnmount: Function.prototype
}

export default XingLogin
