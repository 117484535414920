import React, { createContext, useState } from 'react'

const XingContext = createContext({
  consumerKey: null,
  plugins: [],
  setPlugins: Function.prototype,
  setUser: Function.prototype,
  user: null
})

const XingProvider = ({ children, consumerKey }) => {
  const [user, setUser] = useState(null)
  const [plugins, setPlugins] = useState({})
  const value = { consumerKey, plugins, setPlugins, setUser, user }

  return <XingContext.Provider value={value}>{children}</XingContext.Provider>
}

export { XingContext }

export default XingProvider
