import React from 'react'
import { Route as BaseRoute } from 'react-router-dom'

import DefaultLayout from '../components/DefaultLayout'

const Route = ({ component, ...rest }) => (
  <BaseRoute {...rest} render={routeProps => <DefaultLayout {...routeProps} component={component} />} />
)

Route.defaultProps = {
  layout: 'default'
}

export default Route
