import React from 'react'

import FooterNavbar from './FooterNavbar'
import HeaderNavbar from './HeaderNavbar'

const DefaultLayout = props => {
  const { component: Component, ...routeProps } = props

  return (
    <div className="default-layout">
      <HeaderNavbar />
      <Component {...routeProps} />
      <FooterNavbar />
    </div>
  )
}

export default DefaultLayout
