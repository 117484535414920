import React, { useCallback, useState } from 'react'
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown
} from 'reactstrap'

import descriptions from '../descriptions.json'

const { summary } = descriptions

const ResultSummary = props => {
  const [activeTab, setActiveTab] = useState('openness')
  const value = props[activeTab]
  const { label } = summary[activeTab]
  const type = value >= 0.5 ? 'high' : 'low'
  const content = { label, ...summary[activeTab][type] }

  const handleSelectTab = useCallback(
    (event, id) => {
      event.preventDefault()
      setActiveTab(id)
    },
    [setActiveTab]
  )

  const renderOptions = render => Object.entries(summary).map(([id, { label }]) => render({ id, label }))

  return (
    <div className="result-summary">
      <Nav className="d-none d-md-flex" tabs justified>
        {renderOptions(({ id, label }) => (
          <NavItem active={activeTab === id} key={id}>
            <NavLink
              href="#summary"
              active={activeTab === id}
              onClick={event => handleSelectTab(event, id)}
            >
              {label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <UncontrolledDropdown className="d-block d-md-none">
        <DropdownToggle block color="light" className="rounded-lg border-0 mb-2" caret>
          <span>Ausgewählt:</span>
          <span className="selected">{summary[activeTab].label}</span>
        </DropdownToggle>
        <DropdownMenu className="w-100">
          {renderOptions(({ id, label }) => (
            <DropdownItem
              className="text-center"
              active={activeTab === id}
              key={id}
              onClick={event => handleSelectTab(event, id)}
            >
              {label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <Container className="tab-content" fluid>
        <Row>
          <Col xs={24} md={12}>
            <div className="d-flex flex-column align-items-center">
              <h5 className="font-weight-normal">Beschreibung</h5>
              <p className="mb-0">{content.explanation}</p>
            </div>
          </Col>
          <Col className="pt-4 pt-md-0" xs={24} md={12}>
            <div className="d-flex flex-column align-items-center">
              <h5 className="font-weight-normal">Tipps zum Interview</h5>
              <p className="mb-0">{content.tip}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ResultSummary
