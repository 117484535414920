import React, { useCallback, useEffect, useMemo, useState } from 'react'

import useXing from './useXing'
import translations from '../recorder-translations.json'

const { REACT_APP_PLUGIN_ID } = process.env
const { RetorioRecorder } = window

const useRecorder = id => {
  const [isRendered, setIsRendered] = useState(false)
  const ref = useCallback(node => node && setIsRendered(true), [])
  const { user } = useXing()
  const selector = useMemo(() => `#${id}`, [id])

  const handleFinalScreenContent = useCallback(() => {
    const containerEl = document.createElement('div')
    const thanksTextEl = document.createElement('p')
    const thanksTextContentEl = document.createTextNode(
      'Danke für Deine Teilnahme! Mitgeteilt wird Dir die Auswertung per E-Mail. Für gewöhnlich sollte dies nicht länger als 30 Minuten dauern.'
    )
    const closeTextEl = document.createElement('p')
    const closeTextContentEl = document.createTextNode('Du kannst die Seite schließen.')

    thanksTextEl.className = 'mb-5'
    thanksTextEl.appendChild(thanksTextContentEl)
    closeTextEl.appendChild(closeTextContentEl)
    containerEl.appendChild(thanksTextEl)
    containerEl.appendChild(closeTextEl)

    return containerEl
  }, [])

  const initRecorder = useCallback(() => {
    if (!user) return

    const { display_name, active_email, id, permalink } = user

    RetorioRecorder.createRecorder(
      selector,
      {
        pluginId: REACT_APP_PLUGIN_ID,
        tags: ['xing', 'projobs'],
        meta: { name: display_name, email: active_email, id, permalink },
        session: undefined,
        identityRequired: true,
        preventUpload: false,
        messages: {
          de: translations
        }
      },
      {
        onPrepareFinalScreenContent: handleFinalScreenContent
      }
    )
  }, [handleFinalScreenContent, selector, user])

  useEffect(() => {
    if (isRendered) {
      initRecorder()
    }
  }, [initRecorder, isRendered])

  return useMemo(() => props => <div ref={ref} id={id} {...props} />, [id, ref])
}

export default useRecorder
