import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'

const ChartDescription = ({ children, markerPos }) => (
  <div className="chart-description">
    <div className="marker" style={{ left: markerPos }}>
      <FontAwesomeIcon icon={faCaretUp} />
    </div>
    {children}
  </div>
)

export default ChartDescription
