import React from 'react'
import { NavbarBrand } from 'reactstrap'
import { Link } from 'react-router-dom'

import { ReactComponent as RetorioLogo } from '../assets/svg/retorio-logo.svg'
import { ReactComponent as XINGLogo } from '../assets/svg/xing2.svg'

const Logo = ({ to }) => (
  <NavbarBrand tag={Link} className="brand" to={to}>
    <span className="retorio-logo">
      <RetorioLogo />
    </span>
    <div className="xing-logo">
      <XINGLogo />
    </div>
  </NavbarBrand>
)

export default Logo
