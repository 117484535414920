import React, { useCallback, useContext, useMemo, useRef } from 'react'

import XingLogin from '../components/XingLogin'
import { XingContext } from '../components/XingProvider'

const { REACT_APP_XING_LOGIN_ID } = process.env

const createLoginScript = (onLoad = Function.prototype, onError = Function.prototype) => {
  const id = REACT_APP_XING_LOGIN_ID
  const scriptEl = document.createElement('script')

  scriptEl.id = id
  scriptEl.dataset.type = 'xing/plugin'
  scriptEl.dataset.plugin = 'login'
  scriptEl.src = 'https://www.xing-share.com/plugins/login.js'
  scriptEl.onload = onLoad
  scriptEl.onerror = onError

  document.getElementsByTagName('head')[0].appendChild(scriptEl)
}

const removePluginScript = name => {
  const script = document.querySelector(`[data-type="xing/plugin"][data-plugin="${name}"]`)

  if (script) {
    script.remove()
    window.onXingAuthLogin = null
  }
}

const useXing = () => {
  const { consumerKey, plugins, setPlugins, setUser, user } = useContext(XingContext)
  const loginScripRef = useRef()
  const { xing } = window
  console.log('user', user)
  const handlePluginLoaded = useCallback(
    ({ target: { dataset } }) => {
      const { plugin } = dataset

      if (!plugins[plugin]) {
        setPlugins(currentPlugins => ({ ...currentPlugins, [plugin]: true }))
      }
    },
    [plugins, setPlugins]
  )

  const loadPlugin = useCallback((name, onCallback = Function.prototype) => {
    if (!name) {
      console.error('Error: Please provide a name of the plugin you want to load.')
      return null
    }

    removePluginScript(name)

    switch (name) {
      case 'login':
        createLoginScript(handlePluginLoaded, onCallback)
      // no default
    }
  }, []) // eslint-disable-line

  const LoginButton = useMemo(
    () => props => <XingLogin ref={loginScripRef} consumerKey={consumerKey} {...props} />,
    [consumerKey]
  )

  return { LoginButton, loadPlugin, plugins, setUser, user, xing }
}

export default useXing
