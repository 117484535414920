import React, { useCallback, useEffect, useMemo } from 'react'
import { get } from 'lodash-es'
import { Button } from 'reactstrap'
import useFetch from 'use-http'
import cookie from 'js-cookie'
import cx from 'classnames'

import LoadingSpinner from '../components/LoadingSpinner'
import useRecorder from '../hooks/useRecorder'
import useXing from '../hooks/useXing'

const { REACT_APP_PREFIX, REACT_APP_API_URL, REACT_APP_PLUGIN_ID } = process.env
const retorioCookie = cookie.getJSON(REACT_APP_PREFIX)
const hasCookieAccepted = retorioCookie && retorioCookie.analytics

// limits of recordings for each applicant
const PAYED_APPLICANTS = {
  'fw@vup.at': 6,
  'kai.tiemeyer@t-online.de': 6,
  // retorio devs
  'jan.beuth@hotmail.com': Infinity,
  'denis@retorio.com': Infinity,
}

const RecorderPage = () => {
  const { LoginButton, loadPlugin, plugins, user } = useXing()
  const Recorder = useRecorder('recorder-plugin')
  const xingLoginCookie = cookie.getJSON('xing_plugins_login_with_activated')
  const showLogin = useMemo(() => hasCookieAccepted && !xingLoginCookie && !user, [
    user,
    xingLoginCookie
  ])
  const { data: recordingsData, get: fetchPluginData } = useFetch(`${REACT_APP_API_URL}/plugins/${REACT_APP_PLUGIN_ID}`, {
    onNewData: (_, payload) => payload
  })
  const canRecord = useMemo(() => {
    const email = get(user, 'active_email')
    const recordingsLimit = get(PAYED_APPLICANTS, email, 1)
    const doneRecordingsCount = get(recordingsData, 'count', Infinity)

    return doneRecordingsCount < recordingsLimit
  }, [recordingsData, user])
  const showRecorder = useMemo(() => hasCookieAccepted && !showLogin && canRecord, [
    canRecord,
    showLogin
  ])

  // We can use `user` in our dependency array since the user cannot change without a page reload.
  useEffect(() => {
    if (user) {
      fetchPluginData(`/recordings_count/${user.active_email}`)
    }
  }, [fetchPluginData, user])

  useEffect(() => {
    loadPlugin('login')
  }, [loadPlugin])

  const clearAndRefresh = useCallback(() => {
    cookie.remove(REACT_APP_PREFIX)
    window.location.reload()
  }, [])

  const renderRequirementCheck = () => {
    const isPluginLoaded = plugins.login

    if (isPluginLoaded) {
      if (!hasCookieAccepted) {
        return (
          <>
            <span className="mb-3">
              Damit unser Produkt funktioniert, brauchen wir Deine Zustimmung, dass wir Cookies verwenden
              dürfen.
            </span>
            <Button onClick={clearAndRefresh}>Cookies-Dialog erneut anzeigen</Button>
          </>
        )
      }
      if (showLogin) {
        return <span className="mb-2">Bitte melde Dich mit Deinem XING Konto an:</span>
      }
      if (!recordingsData) {
        return <LoadingSpinner />
      }
      if (!canRecord) {
        return <span>Du hast Deine kostenfreie Aufnahme bereits genutzt.</span>
      }
    }

    return null
  }

  return (
    <div className="recorder-page">
      <div className="error">
        {renderRequirementCheck()}
        <LoginButton className={cx('xing-login', showLogin && 'show')} />
      </div>
      {showRecorder && <Recorder />}
    </div>
  )
}

export default RecorderPage
